import React, { useRef } from 'react';
import { useHistory } from 'react-router';
import { Search } from '../components/Search';
import { ReactComponent as LogoBlack } from '../assets/svg/logo-black.svg';
import MainImg1 from '../assets/img/main-1.png';
import MainImg2 from '../assets/img/main-2.png';
import { Section } from '../components/Section';

export const HomePage = () => {
  const { push } = useHistory();
  const inputRef = useRef<HTMLInputElement>(null);
  return (
    <>
      {/* <div
        className="w-full bg-center bg-cover bg-no-repeat"
        style={{ backgroundImage: `url('${MainImg}')` }}
      > */}
      <div className=" bg-gradient-to-b from-gray-200 to-transparent flex flex-col justify-center items-center space-y-6 h-screen-15">
        <p className="text-15">세상의 모든 음악저작권 정보를 검색하는</p>

        <LogoBlack />
        <div className="pb-1" />
        <Search
          placeholder="찾으시는 곡명 또는 작곡가를 입력해주세요."
          className="w-1/2 border shadow-md"
          ref={inputRef}
          onSearch={() =>
            inputRef.current &&
            push(
              `/results?keyword=${inputRef.current.value?.replace(/[&|]/, '')}`
            )
          }
        />
        {/* </div> */}
      </div>

      <Section className="max-w-screen-lg mx-auto flex justify-between items-center mb-16">
        <div className="space-y-6">
          <h1 className="text-36 font-bold">원하는 악보를 찾아주는,</h1>
          <p className="text-gray-600">
            찾으시는 곡과 작곡가를 입력해보세요. <br />
            여러분이 원하는 악보를 손쉽게 찾아드립니다.
          </p>
        </div>
        <img src={MainImg1} alt="" className="" />
      </Section>

      <Section className="max-w-screen-lg mx-auto flex flex-row-reverse justify-between items-center mb-40">
        <div className="space-y-6">
          <h1 className="text-36 font-bold">저작권 정보도 알려주는,</h1>
          <p className="text-gray-600">
            악보의 저작권이 궁금하셨나요?
            <br />
            뮤직데이터에서는 악보의 저작권 만료여부도 알 수 있어요.
          </p>
        </div>
        <img src={MainImg2} alt="" className="" />
      </Section>

      <h1 className="font-bold text-48 text-center leading-normal">
        이제는 뮤직데이터에서 음악의 저작권정보를
        <br />
        손쉽게 찾아보세요.
      </h1>
    </>
  );
};
