import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { Footer } from '../components/Footer';
import { HomePage } from './HomePage';
import { ResultsPage } from './ResultsPage';

export const Router = () => {
  return (
    <div className="min-h-screen flex flex-col min-w-max lg:min-w-0">
      <main className="flex-1">
        <Switch>
          <Route path="/results" component={ResultsPage} />
          <Route path="/" component={HomePage} exact />
          <Route path="/">
            <Redirect to="/" />
          </Route>
        </Switch>
      </main>

      <Footer />
    </div>
  );
};
