import { useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router';
import qs from 'qs';
import { api } from '../plugins/axios';
import { Checkbox } from '../components/Checkbox';
import { Search } from '../components/Search';
import { ReactComponent as MusicIcon } from '../assets/icons/icon-music.svg';
import { range } from '../utils';
import { Section } from '../components/Section';
import { FrontPagination } from '../components/FrontPagination';
import { ScoreTable } from './ScoreTable';
import { Button } from '../components/Button';
import { PopUp } from '../components/PopUp';
import { Score } from './ScoreTable';
import { ReactComponent as Logo } from '../assets/svg/logo.svg';

export const ResultsPage = () => {
  const { push, location } = useHistory();
  const [popup, setPopup] = useState(false);
  const [popularity, setPopularity] = useState('11111');
  const inputRef = useRef<HTMLInputElement>(null);

  const queryParams = qs.parse(location.search, {
    ignoreQueryPrefix: true,
    depth: 1,
  });
  let keyword = queryParams['keyword'];
  if (typeof keyword !== 'string') keyword = '';
  let page = parseInt(
    typeof queryParams['page'] == 'string' ? queryParams['page'] : ''
  );
  if (isNaN(page) || page <= 0) page = 1;

  const { isLoading, data, isError } = useQuery(
    [page, keyword, popularity],
    ({ queryKey }: any) => {
      return api
        .get('scores', {
          params: {
            page: queryKey[0],
            keyword: queryKey[1],
            popularity: queryKey[2],
          },
        })
        .then(({ data }) => data);
    },
    { keepPreviousData: true, enabled: !!keyword }
  );
  // 더미 데이터를 사용하려면 위 단락 주석처리하고 이 주석 풀기
  // const isLoading = false;
  // const isError = false;
  // const scores: Score[] = [
  //   {
  //     id: 2,
  //     title: 'Boreas Blæs',
  //     subtitle: '(2014)\nBoreas Blows',
  //     info: 'for orchestra',
  //     orch: '2 2 2 2 - 4 2 3(1) 1 - Timp, Perc, Str',
  //     premier: '',
  //     publisher:
  //       'Norsk Musikforlag AS Norsk Musikforlag AS Norsk Musikforlag AS \n ',
  //     len: "5'",
  //     popularity: null,
  //     composerNames: 'Torstein Aagard-Nilsen',
  //     composers: [
  //       {
  //         id: 1,
  //         name: 'Torstein Aagard-Nilsen',
  //         year: '(1964?)',
  //       },
  //     ],
  //   },
  //   {
  //     id: 4,
  //     title: 'title2',
  //     subtitle: 'subtitle2',
  //     info: 'info2',
  //     orch: 'orch2',
  //     premier: 'premier2',
  //     publisher: 'publisher2',
  //     len: 'len2',
  //     popularity: 2,
  //     composerNames: 'Torstein Aagard-Nilsen$composer',
  //     composers: [
  //       {
  //         id: 1,
  //         name: 'aaa',
  //         year: '(1910-1930)',
  //       },
  //       {
  //         id: 2,
  //         name: 'composer',
  //         year: '(1900-1920)',
  //       },
  //     ],
  //   },
  // ];
  // const data = { items: scores, total: 2 };

  const onSearch = (newPage: number) => {
    if (inputRef.current) {
      push(
        `results?keyword=${inputRef.current.value.replace(
          /[&|]/,
          ''
        )}&page=${newPage}`
      );
      window.scrollTo(0, 0);
    }
  };

  const matchNotFound = data && data.total === 0;

  return (
    <>
      <PopUp
        title="라이센스를 문의하시겠어요?"
        description="문의하기를 클릭하시면 정보작성을 위해 구글폼으로 이동합니다."
        onClose={() => setPopup(false)}
        open={popup}
      />

      <div className="bg-gray-900">
        <div className="max-w-screen-xl mx-auto p-4 text-24 font-bold text-white">
          <Logo onClick={() => push('')} className=" cursor-pointer " />
        </div>
      </div>

      <div className=" max-w-screen-xl mx-auto p-4">
        <div className="flex space-x-4 items-center mt-7">
          <div className="border rounded-md shadow-sm flex-1">
            <Search
              placeholder="찾으시는 곡명 또는 작곡가를 입력해주세요."
              defaultValue={keyword}
              ref={inputRef}
              onSearch={() => onSearch(1)}
            />
          </div>

          <Button
            text="검색하기"
            className="filled-gray-800 h-14 px-14"
            onClick={() => onSearch(1)}
          />
        </div>

        <Section className="flex space-x-4 pt-8">
          <span className="">인기순</span>

          {range(5).map((i) => (
            <div className="flex space-x-1 items-center" key={i}>
              <Checkbox
                checked={popularity[i] === '1'}
                onChange={(e) =>
                  setPopularity((p) =>
                    p
                      .substring(0, i)
                      .concat(e.target.checked ? '1' : '0', p.substring(i + 1))
                  )
                }
              />
              <div className="flex">
                {range(i + 1).map((j) => (
                  <MusicIcon className="wh-4" key={j} />
                ))}
              </div>
            </div>
          ))}
        </Section>

        {isLoading ? (
          <></>
        ) : isError ? (
          <div className="text-center grid place-content-center h-96">
            <p className="text-gray-500 text-15">
              서버에서 정보를 가져오는 데 실패했습니다
            </p>
          </div>
        ) : !keyword ? (
          <>
            <div className="text-center grid place-content-center h-96">
              <p className="text-gray-500 text-15">
                <span className="text-48 font-bold">🔍</span>
                <br />
                <br />
                앗! 검색어를 입력하지 않으셨네요!
                <br />
                입력하시면 뮤직데이터가 찾아드릴게요!
              </p>
            </div>
          </>
        ) : matchNotFound ? (
          <>
            <div className="text-center grid place-content-center h-96">
              <p className="text-gray-500 text-15">
                <span className="text-48 font-bold">🔍</span>
                <br />
                <br />
                앗! 검색어에 일치하는 결과가 없어요!
                <br />
                다른 검색어를 입력해보세요!
              </p>
            </div>
          </>
        ) : (
          <>
            <ScoreTable
              scores={data.items as Score[]}
              onClick={() => setPopup(true)}
            />
            <FrontPagination
              page={page}
              count={Math.ceil((data.total as number) / 10)}
              onClick={(newPage: number) => onSearch(newPage)}
            />
          </>
        )}
      </div>
    </>
  );
};
