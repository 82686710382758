import { Section } from '../components/Section';
import { Table } from '../components/Table';
import { Button } from '../components/Button';
import { range } from '../utils';
import { ReactComponent as MusicIcon } from '../assets/icons/icon-music.svg';
import { ButtonHTMLAttributes } from 'react';

interface Composer {
  id: number;
  name: String;
  year: String;
}

export interface Score {
  id: number;
  title: String;
  subtitle: String;
  info: String;
  orch: String;
  premier: String;
  publisher: String;
  len: String;
  popularity: number | null;
  composerNames: String;
  composers: Composer[];
}

interface ScoreTableProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  scores: Score[];
}

export const ScoreTable = ({ scores, ...props }: ScoreTableProps) => {
  const tables = [];
  for (let i = 0; i < scores.length; i++) {
    if (i === 0 || scores[i - 1].composerNames !== scores[i].composerNames)
      tables.push([scores[i]]);
    else tables[tables.length - 1].push(scores[i]);
  }
  return (
    <div className="flex flex-col">
      {tables.map((scoreList) => {
        const expired = scoreList[0].composers.every((composer) => {
          const match = composer.year.match(/\(\d{4}[—-](\d{4})\)/);
          return (
            match !== null &&
            parseInt(match[1]) + 70 <= new Date().getFullYear()
          );
        });
        return (
          <Section className="pt-12 flex flex-col space-y-0">
            <div className="flex flex-col border-b">
              {scoreList[0].composers.map((composer) => (
                <div className="pb-2 text-18 flex space-x-2">
                  <span className="font-bold pl-4">{composer.name}</span>
                  <span className="">{composer.year}</span>
                </div>
              ))}
            </div>
            <Table className="mt-0 table-fixed">
              <Table.Head>
                <Table.Row>
                  <Table.Th className="pl-5">곡 정보</Table.Th>
                  <Table.Th className="">저작권 만료유무</Table.Th>
                  <Table.Th className="">출판사</Table.Th>
                  <Table.Th className="">작품길이</Table.Th>
                  <Table.Th></Table.Th>
                </Table.Row>
              </Table.Head>
              <Table.Body>
                {scoreList.map((item: Score) => (
                  <Table.Row className="group">
                    <Table.Td className="w-1/2 pl-5 group-hover:bg-gray-100 rounded-l-md whitespace-normal break-words">
                      <div className="flex pb-2">
                        {range(item.popularity ? item.popularity : 0).map(
                          (i) => (
                            <MusicIcon
                              className="wh-3 text-gray-900 fill-current"
                              key={i}
                            />
                          )
                        )}
                      </div>
                      <div className="flex space-x-3 items-center">
                        <h1 className="font-bold whitespace-pre">
                          {item.title}
                        </h1>
                        <p className="text-12 text-gray-700">{item.subtitle}</p>
                      </div>
                      <p className="text-gray-600 text-12 pt-3 pb-1">
                        {item.info}
                      </p>
                      <p className="text-gray-600 text-12">{item.orch}</p>
                      <p className="text-gray-600 text-12">{item.premier}</p>
                    </Table.Td>

                    <Table.Td className="w-44 text-12 group-hover:bg-gray-100">
                      {expired && (
                        <div className="pl-4">
                          <Button
                            text="만료"
                            className="filled-red-500 rounded-full h-8"
                          />
                        </div>
                      )}
                    </Table.Td>

                    <Table.Td className="group-hover:bg-gray-100">
                      <p className="break-words whitespace-normal w-32 text-12">
                        {item.publisher}
                      </p>
                    </Table.Td>

                    <Table.Td className="text-12 group-hover:bg-gray-100">
                      {item.len}
                    </Table.Td>

                    <Table.Td className="pl-12 pr-5 group-hover:bg-gray-100 rounded-r-md">
                      <Button
                        {...props}
                        text="라이센스 문의하기"
                        className="group-hover:bg-gray-800 rounded-full text-12 bg-gray-400 text-white active:ring-4 ring-gray-500 ring-inset h-8"
                      />
                    </Table.Td>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </Section>
        );
      })}
    </div>
  );
};
