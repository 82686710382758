import React, { FC } from 'react';
import { Button } from './Button';
import { Icon } from './Icon';

interface PopUpProps {
  title: string;
  description: string;
  open: boolean;
  onClose: () => void;
}

export const PopUp: FC<PopUpProps> = ({
  title,
  description,
  onClose,
  open,
}) => {
  if (!open) return <></>;
  return (
    <>
      <div className="z-50 fixed inset-0 bg-littleblack flex items-center justify-center px-4">
        <div className="md:w-1/3 flex flex-col bg-white rounded-xl overflow-hidden p-6 space-y-4">
          <div className="flex flex-row justify-between">
            <p className="text-20 font-semibold">{title}</p>
            <button
              onClick={onClose}
              className="flex flex-row space-x-2 items-center"
            >
              <Icon.X />
            </button>
          </div>

          <p className="text-15">{description}</p>

          <div className="flex flex-col space-y-4">
            <Button
              onClick={() =>
                window.open('https://forms.gle/AupDZryZucmLLQ2q6', '_blank')
              }
              text="문의하기"
              className="text-15 font-normal filled-gray-800 "
            />
            <Button
              onClick={onClose}
              text="아니요"
              className="text-15 font-normal bg-gray-100 "
            />
          </div>
        </div>
      </div>
    </>
  );
};
